import React from 'react'
import styled from 'styled-components'
import { applyTextStyle } from '../../styles/typography'
import colors from '../../styles/colors'
import Button, { ButtonVariant } from '../Button'
import Text from '../Text'
import strapi from '../../helpers/strapi.helper'
import { SSO } from '../../constants/api'
import { useHistory } from 'react-router-dom'
import Icon from '../icon/Icon'
import { useAppState } from '../../context/AppContext'

interface IProps {
  className?: string
  enrollment?: Enrollement
  course?: Course
  owned?: boolean
}
const CourseCard: React.FC<IProps> = ({ className, enrollment, course, owned }) => {
  const history = useHistory()
  const appState = useAppState()

  if (!course) course = enrollment?.course as Course
  if (!owned) owned = false
  if (course.owned) owned = course.owned

  const getDays = (date: Date) => {
    const date_2 = new Date()
    const difference = date.getTime() - date_2.getTime()
    const TotalDays = Math.ceil(difference / (1000 * 3600 * 24))
    return TotalDays
  }

  let percentage = enrollment?.percentage_completed ? enrollment.percentage_completed : 0
  const enrollments = appState.enrollements
  const checkForEnrollment = enrollments?.find((e) => e.enrollment_name === course?.name)

  //check enrollement and percentage
  if (checkForEnrollment) {
    owned = true
    percentage = checkForEnrollment?.percentage_completed ? checkForEnrollment.percentage_completed : 0
  }

  // console.log('owned', course.name, owned)

  //pecentage from header module
  if (course.moduleHeader) {
    percentage = course.percentage_completed ? course.percentage_completed : 0
  }

  const progress = Math.floor(percentage * 100)
  const isCompleted = progress < 100 ? false : true
  const expirationDate = enrollment?.expiry_date ?? false
  const expirationDays = expirationDate ? getDays(new Date(expirationDate)) : 0
  const isTrial =
    course.name === 'Trial course' ||
    course.name === 'Designing AI Assistants' ||
    course.name === 'CDI Course Materials Tour' ||
    course.name === 'Maximizing Chatbot Success: Metrics and Management'

  return (
    <Card className={className}>
      <Image grayscale={expirationDate && expirationDays < 0 ? 100 : 0 || (!owned && !isTrial) ? 100 : 0} opacity={!owned && !isTrial ? 70 : 100}>
        <img src={course?.img_url ? course.img_url : course?.card_image_url} className="course-img" />
        {!owned && !isTrial && (
          <IconWrapper>
            <Icon size={48} type="Lock" color="white" />
          </IconWrapper>
        )}

        {course.product && (
          <IconWrapper>
            <Icon size={48} type="Lock" color="white" />
          </IconWrapper>
        )}
      </Image>

      <CourseTitle>{enrollment?.enrollment_name ? enrollment.enrollment_name : course.name}</CourseTitle>

      <Text style="label4" color="secondary1" marginBottom={13}>
        {progress + '% complete'}
      </Text>

      <>
        <ProgressBar>
          {isCompleted && <ProgressComplete width={progress} />}
          {!isCompleted && <ProgressDone width={progress} />}
        </ProgressBar>

        {expirationDate && expirationDays >= 10 && (
          <Text style="label4" color="secondary1" marginTop={-20} marginBottom={4}>
            {expirationDays + ' days left'}
          </Text>
        )}

        {expirationDate && expirationDays < 10 && expirationDays > 0 && (
          <Text style="label4" color="tertiary4" marginTop={-20} marginBottom={4}>
            {expirationDays + ` day${expirationDays ? '' : 's'} left`}
          </Text>
        )}

        {expirationDate && expirationDays < 10 && expirationDays == 0 && (
          <Text style="label4" color="tertiary4" marginTop={-20} marginBottom={4}>
            {'Last day!'}
          </Text>
        )}

        {expirationDate && expirationDays < 0 && (
          <Text style="label4" color="tertiary4" marginTop={-20} marginBottom={4}>
            {'License expired!'}
          </Text>
        )}

        {!owned && !isTrial && !course.product && (
          <Button
            variant={ButtonVariant.Secondary}
            caption="Get access"
            onClick={() => {
              openLink(course?.buy_link)
            }}
          ></Button>
        )}

        {course.product && (
          <Button
            variant={ButtonVariant.Secondary}
            caption="Contact Sales"
            onClick={() => {
              openLink(course?.buy_link)
            }}
          ></Button>
        )}

        {!owned && isTrial && (
          <Button
            variant={ButtonVariant.Primary}
            caption="Enroll for course"
            onClick={() => {
              if (course?.img_url) {
                history.push(`/enrollments/${course.name}`)
              } else {
                courseLink(course)
              }
            }}
          ></Button>
        )}

        {progress === 0 && owned && !course.product && (!expirationDate || expirationDays > 0) && (
          <Button
            variant={ButtonVariant.Primary}
            caption="Start"
            onClick={() => {
              if (course?.img_url) {
                history.push(`/enrollments/${course.name}`)
              } else {
                courseLink(course)
              }
            }}
          ></Button>
        )}

        {isCompleted && !owned && (!expirationDate || expirationDays > 0) && (
          <Button
            variant={ButtonVariant.Secondary}
            caption="Open course"
            onClick={() => {
              if (course?.img_url) {
                history.push(`/enrollments/${course.name}`)
              } else {
                courseLink(course)
              }
            }}
          ></Button>
        )}

        {isCompleted && owned && (!expirationDate || expirationDays > 0) && (
          <Button
            variant={ButtonVariant.Secondary}
            caption="Retake course"
            onClick={() => {
              if (course?.img_url) {
                history.push(`/enrollments/${course.name}`)
              } else {
                courseLink(course)
              }
            }}
          ></Button>
        )}

        {!isCompleted && owned && progress > 0 && (!expirationDate || expirationDays >= 0) && (
          <Button
            variant={ButtonVariant.Primary}
            caption="Continue"
            onClick={() => {
              if (course?.img_url) {
                history.push(`/enrollments/${course.name}`)
              } else {
                courseLink(course)
              }
            }}
          ></Button>
        )}
        {!isCompleted && owned && expirationDate && expirationDays < 0 && course && course.buy_link && (
          <Button
            variant={ButtonVariant.Primary}
            color="tertiary4"
            caption="Renew"
            onClick={() => {
              if (enrollment?.img_url) {
                history.push(`/courses/${enrollment.course}`)
              } else {
                storeLink(course)
              }
            }}
          ></Button>
        )}
      </>
    </Card>
  )
}

const courseLink = async (course?: Course) => {
  const ssoLink = await getSSOToken()
  const link = `${process.env.REACT_APP_ACADEMY_URL}courses/take/${course?.slug}`
  const endLink = `${ssoLink}&return_to=${link}`
  openLink(endLink)
}

const storeLink = async (course?: Course) => {
  const ssoLink = await getSSOToken()
  const link = `${process.env.REACT_APP_ACADEMY_URL}courses/${course?.slug}`
  const endLink = `${ssoLink}&return_to=${link}`
  openLink(endLink)
}

const openLink = (link?: string) => {
  if (!link) return
  const newWindow = window.open(link, '_self')
  if (newWindow) newWindow.opener = null
}

const getSSOToken = async () => {
  const link: any = await strapi.request('get', SSO)
  return link.ssoLink
}

const Card = styled.div`
  position: relative;
  display: block;
  margin-bottom: 25px;
`

const IconWrapper = styled.div`
  margin-top: -100px;
  margin-left: 75px;
  opacity: 0.9;
`

const Image = styled.div<{ grayscale: number; opacity: number }>`
  width: auto;
  height: 140px;
  background-color: ${colors.secondary2};
  margin-bottom: 20px;
  filter: grayscale(${({ grayscale }) => grayscale}%);
  opacity: ${({ opacity }) => opacity}%;

  .course-img {
    height: 140px;
    width: 100%;
  }
`
const CourseTitle = styled.div`
  ${applyTextStyle('heading3')}
  margin-bottom: 13px;
  height: 80px;
  overflow: hidden;
`

const ProgressBar = styled.div`
  height: 4px;
  width: 100%;
  background-color: ${colors.secondary2};
  margin-bottom: 31px;
`

const ProgressDone = styled.div<{ width: number }>`
  height: 100%;
  width: ${({ width }) => width}%;
  background-color: ${colors.tertiary2};
`

const ProgressComplete = styled.div<{ width: number }>`
  height: 100%;
  width: ${({ width }) => width}%;
  background-color: ${colors.primary3};
`

export default CourseCard
