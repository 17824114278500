import React from 'react'
import styled from 'styled-components'
import Avatar, { AvatarSize } from '../Avatar'
import Text from '../Text'
import banner from '../../images/banner_bg.png'
import colors from '../../styles/colors'
import Button, { ButtonVariant } from '../Button'
import { useHistory } from 'react-router-dom'
import CertificatesList from '../CertificatesList'
import { useAppState, useAppDispatch } from '../../context/AppContext'
import Link from '../Link'
import { applyTextStyle } from '../../styles/typography'
import { UserHubspotProducts } from '../../enums/userHubspotProducts'
import { baseCDICourses } from '../../common/models/baseCDICourses'
import { cdiCourseModuleOrder } from '../../common/models/cdiCourseModuleOrder'

interface IProps {
  className?: string
  user?: User
}

const ProfileCard: React.FC<IProps> = ({ className, user }) => {
  const history = useHistory()
  const appState = useAppState()
  const appDispatch = useAppDispatch()
  const enrollments = appState.enrollements
  const requestExam = enrollments?.filter((e) => e.percentage_completed && e.percentage_completed >= 1)

  let CXD = false
  let CXDowned = false
  let CC = false
  let CCowned = false
  let AIT = false
  let AITowned = false
  let CAI = false
  let CAIowned = false
  let CPD = false
  let CPDowned = false

  //Calculate process for CDI Courses
  baseCDICourses.forEach((course: Course, index: number) => {
    const cdiCourse = cdiCourseModuleOrder.find((cdiEnrollment) => cdiEnrollment.name === course.name)
    console.log(cdiCourse)

    if (cdiCourse) {
      let percentageSum = 0
      let enrollmentsCount = 0
      for (const moduleName of cdiCourse.enrollments) {
        if (moduleName === 'Downloadables') continue
        const enrollement = enrollments?.find((enrollement) => enrollement.enrollment_name === moduleName)
        if (enrollement) {
          enrollmentsCount++
          percentageSum += enrollement.percentage_completed || 0
        }
      }
      baseCDICourses[index].percentage_completed = percentageSum / enrollmentsCount
    }
  })

  const CXDPercentage = baseCDICourses[0].percentage_completed
  const AIPercentage = baseCDICourses[1].percentage_completed
  const CCPercentage = baseCDICourses[2].percentage_completed
  const CAIPercentage = baseCDICourses[3].percentage_completed
  const CPDPercentage = baseCDICourses[4].percentage_completed

  let showExplainText = false

  user?.hubspot_products?.forEach((product) => {
    // console.log(product.name)
    // console.log(CAIPercentage)

    if (product.name == UserHubspotProducts.CXD && CXDPercentage && CXDPercentage >= 0.8) {
      CXD = true
    }
    if (product.name == UserHubspotProducts.AIT && AIPercentage && AIPercentage >= 0.8) {
      AIT = true
    }
    if (product.name == UserHubspotProducts.CC && CCPercentage && CCPercentage >= 0.8) {
      CC = true
    }
    if (product.name == UserHubspotProducts.CAI && CAIPercentage && CAIPercentage >= 0.8) {
      CAI = true
    }
    if (product.name == UserHubspotProducts.CPD && CPDPercentage && CPDPercentage >= 0.8) {
      CPD = true
    }

    if (product.name == UserHubspotProducts.CXD || product.name == UserHubspotProducts.AIT || product.name == UserHubspotProducts.CC) {
      showExplainText = true
    }

    if (product.name == UserHubspotProducts.CXD) {
      CXDowned = true
    }

    if (product.name == UserHubspotProducts.CC) {
      CCowned = true
    }

    if (product.name == UserHubspotProducts.AIT) {
      AITowned = true
    }

    if (product.name == UserHubspotProducts.CAI) {
      CAIowned = true
    }

    if (product.name == UserHubspotProducts.CPD) {
      CPDowned = true
    }
  })
  const openLink = (link?: string) => {
    if (!link) return
    const newWindow = window.open(link, '_self')
    if (newWindow) newWindow.opener = null
  }

  const openExams = () => {
    appDispatch({
      type: 'updateModalWindow',
      payload: {
        hideActions: false,
        content: (
          <>
            <Text forceStyle="desktop" color="primary1" style="heading8" marginBottom={11} textAlign="center">
              Start the CDI exams to earn your certificates
            </Text>
            {showExplainText && (
              <Text color="primary1" style="label5" marginTop={20} textAlign="center">
                {' '}
                Complete at least 80% of the courses to unluck your exams
              </Text>
            )}
            <Line />
            <TakeExamButton
              color={colors.primary3}
              disabled={!CXD}
              onClick={() =>
                CXD
                  ? openLink('https://www.classmarker.com/online-test/start/?quiz=pr65ecd0bf151746')
                  : openLink('https://www.conversationdesigninstitute.com/courses/conversation-designer-certificate')
              }
            >
              {`Conversational Designer ${CXD ? '' : CXDowned ? '' : '(buy)'}`}
            </TakeExamButton>
            <TakeExamButton
              color={colors.primary1}
              disabled={!AIT}
              onClick={() =>
                AIT
                  ? openLink('https://www.classmarker.com/online-test/start/?quiz=ccn6007100b35f59')
                  : openLink('https://www.conversationdesigninstitute.com/courses/ai-trainer-certificate')
              }
            >
              {`AI Trainer  ${AIT ? '' : AITowned ? '' : '(buy)'}`}
            </TakeExamButton>
            <TakeExamButton
              color={colors.tertiary5}
              disabled={!CC}
              onClick={() =>
                CC
                  ? openLink('https://www.classmarker.com/online-test/start/?quiz=ddr5ebbc479f22af')
                  : openLink('https://www.conversationdesigninstitute.com/courses/conversational-copywriter-certificate')
              }
            >
              {`Conversational Copywriter ${CC ? '' : CCowned ? '' : '(buy)'}`}
            </TakeExamButton>
            <TakeExamButton
              color={colors.tertiary5}
              disabled={!CAI}
              onClick={() =>
                CAI
                  ? openLink('https://www.classmarker.com/online-test/start/?quiz=tcq64e07d8fa03b9')
                  : openLink('https://www.conversationdesigninstitute.com/certificates')
              }
            >
              {`Conversational AI Workflow ${CAI ? '' : CAIowned ? '' : '(buy)'}`}
            </TakeExamButton>
            <TakeExamButton
              color={colors.tertiary5}
              disabled={!CPD}
              onClick={() =>
                CPD
                  ? openLink('https://www.classmarker.com/online-test/start/?quiz=npe655493ebe2d93')
                  : openLink('https://www.conversationdesigninstitute.com/certificates')
              }
            >
              {`Prompt Designer for Conversational AI ${CPD ? '' : CPDowned ? '' : '(buy)'}`}
            </TakeExamButton>
            <RequestExamsCenter>
              Missing access to an exam or do you want to request a re-exam, contact us
              <ExamButton className="exam-request-button" onClick={() => openLink('mailto:support@conversationdesigninstitute.com')}>
                here
              </ExamButton>
              .
            </RequestExamsCenter>
          </>
        ),
      },
    })
  }

  return (
    <>
      {user && (
        <Card className={className}>
          <TopImage></TopImage>
          <Content>
            <AvatarStyled user={user} size={AvatarSize.Large} />
            <Text forceStyle="desktop" color="primary1" style="heading8" marginBottom={11} textAlign="center">
              {calulateName(user)}
            </Text>
            <Text color="primary1" style="label5" marginBottom={4} textAlign="center" opacity={50}>
              {user.headline || 'A mysterious CDI profile'}
            </Text>
            <Text color="primary1" style="label5" marginBottom={23} textAlign="center" opacity={50}>
              {calculateLocation(user)}
            </Text>
            <StyledButton
              caption="Full profile"
              onClick={() => history.push(`/profile/${user.id}`)}
              variant={ButtonVariant.Tertiary}
              textColor="primary2"
              icon="ArrowRight"
              textStyle="label8"
            />
            <Line />
            <ExamBigButton className="shepherd-new-certificate" onClick={() => openExams()}>
              Start exam
            </ExamBigButton>

            <Line />
            <div className="shepherd-certificates">
              {user.certificates && user?.certificates?.length > 0 && <CertificatesList certificates={user.certificates} />}
              {user?.certificates && user?.certificates?.length < 1 && <PlaceholderCertificate> No certificates yet</PlaceholderCertificate>}

              {requestExam && user?.certificates && user?.certificates?.length < 1 && (
                <RequestExams>
                  Request your missing certificates
                  <ExamButton onClick={() => openLink('mailto:support@conversationdesigninstitute.com')}>here</ExamButton>.
                </RequestExams>
              )}
            </div>
            <StyledLink
              caption="View all certficates"
              withUnderline
              onClick={() => openLink('https://www.conversationdesigninstitute.com/certificates.html')}
            />
          </Content>
        </Card>
      )}
    </>
  )
}

const calculateLocation = (user: User) => {
  if (user.city && user.country) return `${user.city}, ${user.country}`
  return 'Whereabouts unkown'
}

const calulateName = (user: User) => {
  if (user.full_name) return user.full_name
  if (!user.full_name && user.first_name && user.last_name) return `${user.first_name} ${user.last_name}`
  return 'Name unknown'
}

const Card = styled.div`
  display: flex;
  flex-direction: column;
`

const AvatarStyled = styled(Avatar)`
  position: absolute;
  top: -48px;
  left: 0;
  right: 0;
  margin: auto;
`

const TopImage = styled.div`
  background: url(${banner}) no-repeat center center;
  background-color: #2c34a8;
  background-size: 135%;
  width: 100%;
  height: 90px;
`

const Content = styled.div`
  padding: 58px 40px 30px 40px;
  background: ${colors.neutralWhite};
  position: relative;
`

const StyledButton = styled(Button)`
  margin: auto;
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.secondary6};
  margin: 30px 0;
`

const RequestExams = styled.div`
  ${applyTextStyle('label4')}
  color: ${colors.primary1};
  opacity: 50%;
`
const RequestExamsCenter = styled.div`
  ${applyTextStyle('label4')}
  color: ${colors.primary1};
  opacity: 50%;
  text-align: center;
`

const ExamButton = styled.span`
  cursor: pointer;
  border-bottom: 2px solid ${colors.tertiary1};
  margin-left: 5px;
`

const StyledLink = styled(Link)`
  float: right;
  margin-top: 30px;
`

const PlaceholderCertificate = styled.div`
  ${applyTextStyle('label5')};
  height: 60px;
  width: 100%;
  margin-bottom: 20px;
  background: ${colors.secondary6};
  padding: 20px 15px 20px 15px;
  color: ${colors.primary1};
  opacity: 50%;
  text-align: center;
`

const ExamBigButton = styled.div`
  ${applyTextStyle('label5')};
  height: 60px;
  width: 100%;
  margin-bottom: 20px;
  background: #fff;
  padding: 20px 15px 20px 15px;
  color: ${colors.primary1};
  border: 1px solid ${colors.primary1};
  text-align: center;
  cursor: pointer;
`

const TakeExamButton = styled.div<{ color: string; disabled: boolean }>`
  ${applyTextStyle('label5')};
  height: 60px;
  width: 100%;
  margin-bottom: 20px;
  background: ${({ color, disabled }) => (disabled ? colors.secondary6 : color)};
  padding: 20px 15px 20px 15px;
  color: ${({ disabled }) => (disabled ? colors.primary2 : '#fff')};
  text-align: center;
  opacity: ${({ disabled }) => (disabled ? '50%' : '100%')};
  cursor: pointer;
`

export default ProfileCard
